import { $http } from "@/utils/https";
import { mapMutations } from "vuex";
import { globalLoader } from "@/helpers/variables";

export default {
  name: "ForumPage",
  data() {
    return {
      content: null,
      tickets: null,
      courseInBasket: [],
      currentPlayingVideo: null,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 1024;
    },
  },
  async created() {
    globalLoader(true);
    await Promise.all([this.fetchContent(), this.fetchTickets()]);
    globalLoader(false);
  },
  methods: {
    ...mapMutations({
      addToBasket: `basket/ADD_ITEM_TO_BASKET`,
      addWebinarToBasket: "basket/ADD_WEBINAR_ITEM_TO_BASKET",
      changeBasketPopup: "basket/SHOW_BASKET_POPUP",
    }),
    async fetchContent() {
      const content = await $http.get("v1/pages/skinon-vs-acne");
      this.content = content.data.data;
    },

    async fetchTickets() {
      const tickets = await $http.get("v1/tickets");
      this.tickets = tickets.data.data;
    },

    buyTicket(product) {
      const productPrices = { ...product.productPrices.data[0] };
      productPrices.quantity = 1;
      productPrices.price = productPrices.basePrice;
      this.addWebinarToBasket({
        select_count: 1,
        product,
        product_price: productPrices,
      });
      this.changeBasketPopup(true);
      this.checkCoursesInBasket(product.id);
      this.$toasted.success("Квиток успішно додано в кошик");
    },
    checkCoursesInBasket(id) {
      this.courseInBasket.push(id);
    },
    toggleVideo(videoId) {
      const video = this.$refs[`video-${videoId}`][0];

      if (this.currentPlayingVideo === videoId) {
        video.pause();
        this.currentPlayingVideo = null;
      } else {
        if (this.currentPlayingVideo) {
          const currentVideo = this.$refs[`video-${this.currentPlayingVideo}`][0];
          currentVideo.pause();
          currentVideo.currentTime = 0;
        }

        video.muted = false;
        video.play();
        this.currentPlayingVideo = videoId;
      }
    },

    stopVideo(videoId) {
      const video = this.$refs[`video-${videoId}`][0];
      video.pause();
      video.currentTime = 0;
      this.currentPlayingVideo = null;
    },
  },
};
